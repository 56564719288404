import React, { Component } from 'react';
import axios from 'axios';

import Thanks from '../Thanks';
import Input, { Textarea } from '../forms/input';

class SMMPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formdata: {},
      showThanks: false,
      loadedThanks: false,
      step: 1,
      showOther: false,
      platforms: props.platform ? [props.platform] : [],
      smmPackages: [],
      disabled: true,
      req: false,
      loading: false,
    };

    this.stepOne = React.createRef();
    this.stepTwo = React.createRef();
    this.stepTree = React.createRef();

    this.handleChange = this.handleChange.bind(this);

    this.hideThanksModal = this.hideThanksModal.bind(this);
    // this.disableNextBtn = this.disableNextBtn.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    console.log(2);
    // this.setState({step:nextProps.step})

    // if(nextProps.platforms != this.state.platforms) this.setState({platforms:nextProps.platform ? [nextProps.platform] : []})
  }

  handleChange(param, value) {
    var formdata = this.state.formdata;

    if (param == 'smmPackage') {
      if (document.getElementById('other_ch').checked) this.setState({ showOther: true });
      else this.setState({ showOther: false });

      let checkboxes = document.getElementsByName('smmPackage');
      let checkboxesChecked = [];

      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      if (document.getElementById('other_ch').checked) {
        // console.log(document.getElementById('other'))
        checkboxesChecked.push(document.getElementById('other').value);
      }
      console.log(checkboxes, checkboxesChecked, 123);
      if (checkboxesChecked.length >= 1) {
        this.setState({ smmPackages: checkboxesChecked });
      } else {
        this.setState({ smmPackages: [] });
      }
    }

    if (param == 'platform') {
      let checkboxes = document.getElementsByName('platform');
      let checkboxesChecked = [];
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          checkboxesChecked.push(checkboxes[i].value);
        }
      }
      if (checkboxesChecked.length >= 1) {
        this.setState({ platforms: checkboxesChecked });
      } else {
        this.setState({ platforms: [] });
      }
    }

    this.setState(prevState => {
      let formdata = Object.assign({}, prevState.formdata);
      formdata[param] = value;
      return { formdata };
    });

    const that = this;
    setTimeout(function () {
      let disabled = false;
      const { step, platforms, smmPackages } = that.state;
      if (step == 1) {
        // let advBudget = document.querySelector('[name=include-adv-budget]:checked')
        // disabled =  platforms.length == 0 ||  smmPackages.length == 0 || advBudget ==  null;
        disabled = platforms.length == 0 || smmPackages.length == 0;
      }
      if (step == 2) {
        let companyName = document.getElementById('cName').value;
        let companyWebsite = document.querySelector('[name=company-website]').value;
        disabled = companyName == '' || companyWebsite == '';
      }
      that.setState({ disabled: disabled });
    }, 200);
  }
  hideThanksModal() {
    this.setState({
      loadedThanks: false,
      showThanks: false,
    });
    document.body.style.overflow = 'auto';

    document.body.style.overflowX = 'hidden';
  }
  onSubmit(e) {
    e.preventDefault();

    var that = this;
    this.setState({ loading: true });
    var form_data = new FormData();

    for (var key in this.state.formdata) {
      form_data.append(key, Array.isArray(this.state.formdata[key]) ? this.state.formdata[key].join(',') : this.state.formdata[key]);
    }
    let arr = this.state.smmPackages;
    if (document.getElementById('other')) arr.push(document.getElementById('other').value);
    form_data.append('country', this.props.country);
    form_data.append('smmPackage', arr.join(' , '));
    form_data.append('platform', this.state.platforms.join(' , '));

    // that.props.hide();

    // that.setState({showThanks:true});

    document.body.style.overflow = 'hidden';

    axios.post('https://mailer.weprodigi.com/actions/smmPricing.php', form_data).then(response => {
      // this.setState({loading:false})

      // that.setState({loadedThanks:true});
      window.location.href = 'https://weprodigi.com/thank-you';
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.step != this.state.step) {
      let disabled = false;
      const { step, platforms, smmPackages } = this.state;
      if (step == 1) {
        // let advBudget = document.querySelector('[name=include-adv-budget]:checked')

        // disabled =  platforms.length == 0 ||  smmPackages.length == 0 || advBudget ==  null;
        disabled = platforms.length == 0 || smmPackages.length == 0;
      }
      if (step == 2) {
        let companyName = document.getElementById('cName').value;
        let companyWebsite = document.querySelector('[name=company-website]').value;
        disabled = companyName == '' || companyWebsite == '';
      }
      this.setState({ disabled: disabled });
    }
  }

  setPlatformsCheckbox = (name, value) => {
    let arr = this.state.platforms;
    if (!arr.includes(value)) {
      arr.push(value);
    } else {
      arr = arr.filter(function (val) {
        return val != value;
      });
    }

    this.setState({ platforms: arr });
  };

  setSmmPackagesChckbox = (name, value) => {
    let arr = [];
    arr.push(value);
    this.setState({ smmPackages: arr });
  };
  hide = () => {
    // this.setState({formdata:{},req:false,platforms:[],smmPackages:[]})
    this.props.hide();
  };
  emailValidation = email => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };
  render() {
    const { step, loading, showOther, platforms, smmPackages, disabled } = this.state;
    return (
      <>
        <div
          className={
            'fixed z-900 bg-navbar w-full h-full left-0 top-0 right-0 bottom-0 items-center justify-center' +
            ' ' +
            (this.props.show && !this.state.showThanks ? 'flex' : 'hidden')
          }
        >
          <div className="absolute left-0 top-0 w-full h-full" onClick={this.hide} />

          <div className="relative transform w-11/12 max-w-screen-md max-h-screen-md bg-gray border border-gray-transparent rounded-5 md:scale-100">
            <div
              onClick={this.hide}
              className="absolute -right-12 -top-12 bg-purple text-white w-30 h-30 text-21 rounded-full flex items-center justify-center cursor-pointer select-none"
            >
              <span className="font-bold">X</span>
            </div>

            <div className="py-23 px-50 max-h-650  overflow-y-auto">
              <p className="text-purple uppercase text-24 text-center font-black leading-none">Get SMM Pricing</p>

              <p className="text-black text-12 mt-20">
                {' '}
                Kindly answer a few questions in order for us to provide you with pricing information for a custom SMM package. An email address is
                required in the form.*
              </p>
              <form id="offer-form-smm" method="POST" onSubmit={e => this.onSubmit(e)}>
                <div ref={this.stepOne} className={`mt-20 ${step == 1 ? 'block' : 'hidden'}`}>
                  <p className={'text-16 flex items-center flex-wrap text-purple font-bolder'}>
                    Which platforms should be included in the SMM package?{' '}
                    {this.state.req && platforms.length == 0 && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>
                  <div className={'flex items-start justify-between'}>
                    <div className="form-group mr-5  w-half-auto select-none">
                      <div className=" mt-15 mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Facebook');
                          }}
                          className={`${platforms.includes('Facebook') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Facebook</span>
                          {platforms.includes('Facebook') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Instagram');
                          }}
                          className={`${platforms.includes('Instagram') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Instagram</span>
                          {platforms.includes('Instagram') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'LinkedIn');
                          }}
                          className={`${platforms.includes('LinkedIn') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">LinkedIn</span>
                          {platforms.includes('LinkedIn') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Twitter');
                          }}
                          className={`${platforms.includes('Twitter') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Twitter</span>
                          {platforms.includes('Twitter') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>

                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Youtube');
                          }}
                          className={`${platforms.includes('Youtube') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Youtube</span>
                          {platforms.includes('Youtube') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mr-5  w-half-auto select-none">
                      <div className="mt-15 mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Snapchat');
                          }}
                          className={`${platforms.includes('Snapchat') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Snapchat</span>
                          {platforms.includes('Snapchat') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Pinterest');
                          }}
                          className={`${platforms.includes('Pinterest') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Pinterest</span>
                          {platforms.includes('Pinterest') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className=" mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'TikTok');
                          }}
                          className={`${platforms.includes('TikTok') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">TikTok</span>
                          {platforms.includes('TikTok') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <div className=" mb-10">
                        <div
                          onClick={() => {
                            this.setPlatformsCheckbox('platforms', 'Recommend me');
                          }}
                          className={`${platforms.includes('Recommend me') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Recommend me</span>
                          {platforms.includes('Recommend me') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className={'text-16 flex items-center flex-wrap text-purple font-bolder mt-20'}>
                    How many posts should be included in the SMM package? (Monthly Basis){' '}
                    {this.state.req && (smmPackages.length == 0 || (smmPackages.includes('Other') && !this.state.formdata['other'])) && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>

                  <div className={'flex justify-between'}>
                    <div className="form-group   w-half-auto select-none">
                      <div className=" mt-15 mb-10">
                        <div
                          onClick={() => {
                            this.setSmmPackagesChckbox('smmPackages', 'Less than 8 posts');
                          }}
                          className={`${smmPackages.includes('Less than 8 posts') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Less than 8 posts</span>
                          {smmPackages.includes('Less than 8 posts') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setSmmPackagesChckbox('smmPackages', '10-12 posts');
                          }}
                          className={`${smmPackages.includes('10-12 posts') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">10-12 posts</span>
                          {smmPackages.includes('10-12 posts') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setSmmPackagesChckbox('smmPackages', '12-20 posts');
                          }}
                          className={`${smmPackages.includes('12-20 posts') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">12-20 posts</span>
                          {smmPackages.includes('12-20 posts') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half select-none">
                      <div className=" mt-15 mb-10">
                        <div
                          onClick={() => {
                            this.setSmmPackagesChckbox('smmPackages', '20-30 posts');
                          }}
                          className={`${smmPackages.includes('20-30 posts') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">20-30 posts</span>
                          {smmPackages.includes('20-30 posts') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setSmmPackagesChckbox('smmPackages', 'Recommend me');
                          }}
                          className={`${smmPackages.includes('Recommend me') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Recommend me</span>
                          {smmPackages.includes('Recommend me') && (
                            <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />
                          )}
                        </div>
                      </div>
                      <div className="mb-10">
                        <div
                          onClick={() => {
                            this.setSmmPackagesChckbox('smmPackages', 'Other');
                          }}
                          className={`${smmPackages.includes('Other') ? 'border-1 border-green ' : 'border-0 '} flex justify-between px-10 bg-white items-center h-36 rounded-5 cursor-pointer`}
                        >
                          <span className="text-16 leading-20 select-none">Other</span>
                          {smmPackages.includes('Other') && <img src="https://admin.weprodigi.com/uploads/files/shares/ppc/check.png" alt="check" />}
                        </div>
                      </div>
                      <input
                        className={`pl-10 rounded-5 ${smmPackages.includes('Other') ? 'block' : 'hidden'} w-full h-36 focus:placeholder-white   focus:outline-none py-2`}
                        type="text"
                        name={'other'}
                        id={'other'}
                        onChange={this.handleChange}
                        placeholder={'amount of posts'}
                      />
                    </div>
                  </div>
                  <p className={'text-16 text-purple font-bolder mt-20'}>Do you want to include the advertising budget in the SMM package?</p>
                  <div className={'flex justify-between mt-15'}>
                    <div className="form-group   w-half">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'include-adv-budget' + (this.props.id ? this.props.id : '')}
                          name="include-adv-budget"
                          value="Yes"
                          onChange={() => this.handleChange('include-adv-budget', 'Yes')}
                        />
                        <label htmlFor={'include-adv-budget' + (this.props.id ? this.props.id : '')}>Yes</label>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'include-adv-budget-no' + (this.props.id ? this.props.id : '')}
                          name="include-adv-budget"
                          value="No"
                          onChange={() => this.handleChange('include-adv-budget', 'No')}
                        />
                        <label htmlFor={'include-adv-budget-no' + (this.props.id ? this.props.id : '')}>No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div ref={this.stepTwo} className={`mt-20 ${step == 2 ? 'block' : 'hidden'}`}>
                  <p className={'text-16 text-purple font-bolder'}>
                    Company Name*{' '}
                    {this.state.req && !this.state.formdata['company-name'] && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>
                  <div className={'w-full mt-5'}>
                    <Input
                      type="text"
                      name="company-name"
                      id={'cName'}
                      bg="bg-white w-full focus:outline-none"
                      required={true}
                      onChange={this.handleChange}
                    />
                  </div>
                  <p className={'text-16 text-purple font-bolder mt-20'}>
                    Company Website*{' '}
                    {this.state.req && !this.state.formdata['company-website'] && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}{' '}
                  </p>
                  <div className={'w-full mt-5'}>
                    <Input type="text" name="company-website" bg="bg-white w-full focus:outline-none" required={true} onChange={this.handleChange} />
                  </div>
                  <p className={'text-16 text-purple font-bolder mt-20'}>Information that you want to share with us</p>
                  <div className={'w-full mt-5'}>
                    <Textarea
                      type="text"
                      placeholder={'Main products, goals, deadlines & etc.'}
                      name="description"
                      bg="resize-none bg-white w-full focus:outline-none"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div ref={this.stepTree} className={`mt-20 ${step == 3 ? 'block' : 'hidden'}`}>
                  <p className={'text-16 text-purple flex items-center flex-wrap font-bolder'}>
                    Your Name *{' '}
                    {this.state.req && !this.state.formdata['name'] && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}
                  </p>
                  <div className={'w-full mt-5'}>
                    <Input type="text" name="name" bg="bg-white w-full focus:outline-none" required={true} onChange={this.handleChange} />
                  </div>
                  <p className={'text-16 text-purple flex items-center flex-wrap font-bolder mt-20'}>
                    Your Email *
                    {this.state.req && !this.state.formdata['email'] && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        This is required
                      </span>
                    )}
                    {this.state.req && this.state.formdata['email'] && !this.emailValidation(this.state.formdata['email']) && (
                      <span
                        className="bg-green animate-warning text-white text-12 normal-case font-normal px-5 rounded-5"
                        style={{ verticalAlign: 'super' }}
                      >
                        Email is not valid
                      </span>
                    )}
                  </p>
                  <div className={'w-full mt-5'}>
                    <Input type="email" name="email" bg="bg-white w-full focus:outline-none" required={true} onChange={this.handleChange} />
                  </div>
                  <p className={'text-16 text-purple font-bolder mt-20'}>Your Phone Number </p>
                  <div className={'w-full mt-5'}>
                    <Input type="text" name="phone" bg="bg-white w-full focus:outline-none" onChange={this.handleChange} />
                  </div>

                  <p className={'text-16 text-purple font-bolder mt-20'}>What’s the best way to contact you?</p>

                  <div className="flex flex-row mt-15">
                    <div className="form-group mr-30">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'contact-via-email-1' + (this.props.id ? this.props.id : '')}
                          name="contact-via"
                          value="Email"
                          onChange={() => this.handleChange('contact-via', 'Email')}
                        />
                        <label htmlFor={'contact-via-email-1' + (this.props.id ? this.props.id : '')}>Email</label>
                      </div>
                    </div>
                    <div className="form-group mr-30">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'contact-via-phone-1' + (this.props.id ? this.props.id : '')}
                          name="contact-via"
                          value="Phone"
                          onChange={() => this.handleChange('contact-via', 'Phone')}
                        />
                        <label htmlFor={'contact-via-phone-1' + (this.props.id ? this.props.id : '')}>Phone</label>
                      </div>
                    </div>
                    <div className="form-group mr-30">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'contact-via-both-1' + (this.props.id ? this.props.id : '')}
                          name="contact-via"
                          value="Both"
                          onChange={() => this.handleChange('contact-via', 'Both')}
                        />
                        <label htmlFor={'contact-via-both-1' + (this.props.id ? this.props.id : '')}>Both</label>
                      </div>
                    </div>
                  </div>
                  <p className="text-14 mt-15 font-bolder text-black mb-5">Send NDA</p>
                  <div className={'flex justify-between mt-15'}>
                    <div className="form-group    w-half">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'nda-yes-1' + (this.props.id ? this.props.id : '')}
                          name="nda"
                          value="Yes"
                          onChange={() => {
                            this.handleChange('nda', 'Yes');
                          }}
                        />
                        <label htmlFor={'nda-yes-1' + (this.props.id ? this.props.id : '')}>Yes</label>
                      </div>
                    </div>
                    <div className="form-group mr-5  ml-10 w-half">
                      <div className="radio-item">
                        <input
                          type="radio"
                          id={'nda-no-1' + (this.props.id ? this.props.id : '')}
                          name="nda"
                          value="No"
                          onChange={() => {
                            this.handleChange('nda', 'No');
                          }}
                        />
                        <label htmlFor={'nda-no-1' + (this.props.id ? this.props.id : '')}>No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'w-full prev-next-part flex justify-between text-white mt-20'}>
                  <div>
                    {step == 2 && (
                      <button
                        className={'flex hover:bg-purple items-center bg-green rounded-5 px-10 py-5 text-14'}
                        type={'button'}
                        onClick={e => {
                          this.setState({ step: 1 });
                          this.stepOne.current.scrollIntoView();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Previous
                      </button>
                    )}
                    {step == 3 && (
                      <button
                        className={'flex hover:bg-purple items-center bg-green rounded-5 px-10 py-5 text-14'}
                        type={'button'}
                        onClick={e => {
                          this.setState({ step: 2 });
                          this.stepTwo.current.scrollIntoView();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Previous
                      </button>
                    )}
                  </div>

                  <div>
                    {step == 1 && (
                      <button
                        className={'flex hover:bg-purple items-center bg-green rounded-5 px-10 py-5 text-14'}
                        type={'button'}
                        onClick={e => {
                          if (
                            smmPackages.length == 0 ||
                            (smmPackages.includes('Other') && !document.getElementById('other').value) ||
                            platforms.length == 0
                          ) {
                            this.setState({ req: true });
                          } else {
                            this.setState({ step: 2 });
                            this.stepTwo.current.scrollIntoView();
                            this.setState({ req: false });
                          }
                          // if((smmPackages.length != 0 || (smmPackages.includes('Other') &&  this.state.formdata['other'])) && platforms.length != 0){
                          //     this.setState({step:2})
                          //     this.setState({req:false})
                          // }else{
                          //     this.setState({req:true})
                          // }
                        }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                    {step == 2 && (
                      <button
                        className={'flex hover:bg-purple items-center bg-green rounded-5 px-10 py-5 text-14'}
                        type={'button'}
                        onClick={e => {
                          let cName = this.state.formdata['company-name'];
                          let cWebsite = this.state.formdata['company-website'];
                          if (cName && cWebsite) {
                            this.setState({ step: 3 });
                            this.stepTree.current.scrollIntoView();
                            this.setState({ req: false });
                          } else {
                            this.setState({ req: true });
                          }
                        }}
                      >
                        Next
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={15}
                          height={15}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
                <div className="loader-part flex w-full mt-20">
                  <div
                    className={`w-full border-t-2 ${step == 1 || step == 2 || step == 3 ? 'border-green' : 'border-gray-slider'} flex justify-center text-center relative`}
                  >
                    <button
                      className={`cursor-pointer w-20 h-20 flex justify-center items-center text-12 rounded-50p text-white  ${step == 1 || step == 2 || step == 3 ? 'bg-green ' : '  bg-gray-slider'} absolute -top-10`}
                      onClick={() => {
                        // this.setState({step: 1})
                      }}
                    >
                      1
                    </button>
                  </div>
                  <div
                    className={`w-full border-t-2 ${step == 2 || step == 3 ? 'border-green' : 'border-gray-slider'} flex justify-center text-center relative`}
                  >
                    <button
                      className={`cursor-pointer w-20 h-20 flex justify-center items-center text-12 rounded-50p text-white  ${step == 2 || step == 3 ? 'bg-green ' : '  bg-gray-slider'} absolute -top-10`}
                      onClick={() => {
                        // this.setState({step: 2})
                      }}
                    >
                      2
                    </button>
                  </div>
                  <div className={`w-full border-t-2 ${step == 3 ? 'border-green' : 'border-gray-slider'} flex justify-center text-center relative`}>
                    <button
                      className={`cursor-pointer w-20 h-20 flex justify-center items-center text-12 rounded-50p text-white  ${step == 3 ? 'bg-green ' : '  bg-gray-slider'} absolute -top-10`}
                      onClick={() => {
                        // this.setState({step: 3})
                      }}
                    >
                      3
                    </button>
                  </div>
                </div>
                {step != 3 ? (
                  <div className={'mt-20 text-center text-12 text-black leading-15'}>*An email address is required in the form</div>
                ) : (
                  <div className={'mt-20 text-center flex justify-center'}>
                    <button
                      id={'offer-submit'}
                      className={'text-white text-16 items-center gap-1 flex bg-purple hover:bg-green rounded-5 px-20 py-5 capitalize'}
                      onClick={e => {
                        let name = this.state.formdata['name'];
                        let email = this.state.formdata['email'];
                        if (name && email && this.emailValidation(email)) {
                          e.preventDefault();
                          this.setState({ req: false });
                          this.onSubmit(e);
                        } else {
                          this.setState({ req: true });
                        }
                      }}
                      disabled={loading}
                      type="button"
                    >
                      {loading && (
                        <svg className="pl" viewBox="0 0 200 200" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                          <defs>
                            <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                              <stop offset="0%" stop-color="hsl(313,90%,55%)" />
                              <stop offset="100%" stop-color="hsl(223,90%,55%)" />
                            </linearGradient>
                            <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="0%" stop-color="hsl(313,90%,55%)" />
                              <stop offset="100%" stop-color="hsl(223,90%,55%)" />
                            </linearGradient>
                          </defs>
                          <circle
                            className="pl__ring"
                            cx="100"
                            cy="100"
                            r="82"
                            fill="none"
                            stroke="url(#pl-grad1)"
                            stroke-width="36"
                            stroke-dasharray="0 257 1 257"
                            stroke-dashoffset="0.01"
                            stroke-linecap="round"
                            transform="rotate(-90,100,100)"
                          />
                          <line
                            className="pl__ball"
                            stroke="url(#pl-grad2)"
                            x1="100"
                            y1="18"
                            x2="100.01"
                            y2="182"
                            stroke-width="36"
                            stroke-dasharray="1 165"
                            stroke-linecap="round"
                          />
                        </svg>
                      )}
                      submit
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <Thanks show={this.state.showThanks} loaded={this.state.loadedThanks} hide={this.hideThanksModal} backUrl={'/social-media-marketing'}>
          One of our account managers will contact you and provide you with SMM pricing information. If you don’t receive an email from us within 24
          hours please check your Spam Box.
        </Thanks>
      </>
    );
  }
}

export default SMMPricing;
